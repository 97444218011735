
import { defineComponent, onBeforeMount, reactive, toRefs } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'

import { indexApi } from '@/api/modules/index'

export default defineComponent({
  name: 'documentList',
  setup (props, ctx) {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()

    onBeforeMount(() => {
      const { title } = route.query
      data.navTitle = title
      data.getList()
    })

    const data: any = reactive({
      selectedItem: 0,
      navTitle: '',
      list: null,
      encryptData: store.state.app.encryptData,

      async getList () {
        const { res } = await indexApi.handbookList({
          name: data.navTitle
        })
        console.log('操作手册列表', res[0])
        data.list = res[0].children || []
      },
      // 详情跳转
      onDetails (id: number) {
        router.push(`/document/details?dataItem=${data.encryptData}&id=${id}`)
      }
    })

    return {
      ...toRefs(data)
    }
  }
})
